import React from 'react';
import { Link } from 'react-router-dom'; // For navigation links (optional)
import { HashLink} from 'react-router-hash-link';
import styled from 'styled-components';
import CallToAction from './Shared/CallToAction';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #f5f5f5; /* Light gray background */
`;

const Logo = styled.img`
  height: 50px; /* Adjust logo height */
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 2rem;
  vertical-align: middle;

`;

const NavLink = styled.li`
  margin-right: 1rem;
  padding: 0 1rem;

  &:hover {
    /*background-color: #ccc; */
    border: 1px solid #aaa;
    border-radius: 5px;
  }
`;

const NavIcon = styled.li`
  margin-right: 1rem;

  &:hover {
    transform: scale(1.5);
  }
`;


const Header = () => {
  return (
    <HeaderContainer>
      <HashLink to="/">
        <Logo src="logo.png" alt="ClubDigital.ma" />
      </HashLink>
      <NavLinks>
        <NavLink>
          <HashLink to="#about">About</HashLink>
        </NavLink>
        <NavLink>
          <HashLink to="#articles" className='page-scroll'>Articles</HashLink>
        </NavLink>
        <NavLink>
          <HashLink to="#contact">Contact</HashLink>
        </NavLink>
        <NavIcon>
          <Link to="https://www.linkedin.com/showcase/clubdigital-ma" target="_blank" rel="noopener noreferrer">
            <img src="https://akademy.ma/images/linkedin.png" alt="linkedin" title="linkedin" />
            </Link>
        </NavIcon>
        <NavIcon>
          <Link to="https://www.facebook.com/people/ClubDigitalma/61557742675040" target="_blank" rel="noopener noreferrer">
            <img src="https://akademy.ma/images/facebook.png" alt="facebook" title="facebook" />
            </Link>
        </NavIcon>

        <CallToAction />
      </NavLinks>
    </HeaderContainer>
  );
};

export default Header;
