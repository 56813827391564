
const Config = {
    salaam:'Salaam',
    title :'ClubDigital.ma',
    logo : 'logo.png'
}

const header = {
    joinus : "Join us",
    signin : "Login",    
    signout : "Exit"
}

const home = {
    title:'Club Digital',
    about:'ClubDigital.ma aims to introduce the new generation to Digital professions and programming languages, particularly Python and Js'
}

const blog = {
    title :'Our most interesting articles',
    read:'Read more...'
}

export default Config
export {header, home, blog}