import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { HashLink as Link } from 'react-router-hash-link'
import {blog} from './Config/en'
import {Read} from './Shared/Api'

const ArticlesContainer = styled.section`
max-width: 1024px;
margin: auto;
  padding: 2rem 0;
  text-align: center;
`;

const ArticleCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const ArticleIcon = styled.div`
  font-size: 2rem;
  width: 8rem;
  aspect-ratio : 1 / 1;
  background-size: contain;
  background-image: url('landing.jpg');
`;

const ArticleTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const ArticleDescription = styled.p`
  font-size: 1rem;
`;

const ArticleLink = styled.a`
  font-size: 1rem;
`;

const Articles = () => {

  const [domain, Domain] = useState(10)
  const [loaded, Load] = useState(false)
  const [items , Items] = useState([])

  useEffect(()=>{        

    if(domain>0) {
      Read(`/blog/${domain}`, Items, Load)
      //console.log(items)
    }

},[domain])


  const articles = [
    {
      icon: 'fas fa-lightbulb', 
      title: 'Code Your Future: Join the "Coding for All" community!',
      image: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiFLNEk8wNZ-g0T6HINmQw39uAAi3ZgTVUnPyZG-nFdG7h4XASlUGuutMMA0b5aVhmV8fYNr3sE7ZMRjhIqoqPRJF7qH6kkOw2a4EoHrDnoeUzlaglG-79fnLqgLnNog0VOmAEwFaj-hBwaYf0_m_7qAuT3OWiwnu8cNBsaJ-PJYz7O-FCBgh2gsQvu2Njn/s1024/Coding%20for%20All.jpeg',
      description: 'The world around you is powered by code. From the games you play to the websites you browse, code is the language that makes technology tick.',
      link:'https://digitalminding.blogspot.com/2024/03/code-your-future-join-coding-for-all.html'
    },
    {
      icon: 'fas fa-lightbulb', // Replace with your icon class (e.g., from Font Awesome)
      image: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjuPTYZXEfWbNShFkKZeQqsEt1FpSQQ60H8AZm_kTMZ44cZNiNpug14eNr66XVG8cy4yXY15Hxby8Ck8Qm_wn5hb5pJKLTphrCE0aw4gj6xahzbKCpGq0RaXWuV40Ooivi4b2mhBE3jD-asTV4y9s9VPIENohhXUuL0sjLf3pxNuy7zSHQmkva7xw0yQTFT/s1024/digital%20skills%201.jpeg',
      title: 'The essential digital skills to improve',
      description: 'For a new student, some essential digital skills to improve includes the following 10 topics:',
      link:'https://digitalminding.blogspot.com/2024/03/the-essential-digital-skills-to-improve.html'
    },
    {
      icon: 'fas fa-lightbulb', 
      image:'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEihxc9AU0CzeWi4urVxiJeJnyZgFHxlXPTjMLisxVid9kde1PAzjuMzVbx9Rgw925kcAPVmYEmtfpp8oDBtr9R9sMinnyHp1bUqvhFcow8MZLQfs5OoXtNFUk3aUYEsBU0IFnKkkmwCDc2m-Ii9z5r5Qts8i6Itu94E-r2VjWxG3l3XgEsf1S_KFPsznb0P/s1024/Why%20do%20we%20have%20to%20learn%20python.jpeg',
      title: 'Why do we have to learn python?',
      description: 'Learning programming language, particularly Python, offers numerous benefits regardless of your specific career path or interests.',
      link:'https://digitalminding.blogspot.com/2024/03/why-do-we-have-to-learn-python.html'
    }
  ];

  return (
    <ArticlesContainer id="articles">
      <h2>{blog.title}</h2>

      <div className="articles-grid">
        {items.map((item) => (
          <ArticleCard key={'Article-'+item.Id}>
            <ArticleIcon className="article-card" style={{backgroundImage: `url(${item.Image})`}} />
            <ArticleTitle>{item.Title}</ArticleTitle>
            <ArticleDescription>{item.Introduction}</ArticleDescription>
              <ArticleLink href={item.Link} target="_blank" rel="noopener noreferrer">
                {blog.read}
              </ArticleLink>
          </ArticleCard>
        ))}
      </div>

      <div className="articles-grid">
        {articles.map((article) => (
          <ArticleCard key={article.title}>
            <ArticleIcon className={article.icon} style={{backgroundImage: `url(${article.image})`}} />
            <ArticleTitle>{article.title}</ArticleTitle>
            <ArticleDescription>{article.description}</ArticleDescription>
              <ArticleLink className={article.icon} href={article.link} target="_blank" rel="noopener noreferrer">
                {blog.read}
              </ArticleLink>
          </ArticleCard>
        ))}
      </div>

      <Link to="#about" className='link'>About</Link>
    </ArticlesContainer>
  );
};

export default Articles;
